/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { calculateStepPricing } from '@/utils/cart.utils'
import { ActionContext } from 'vuex'
import PromotionCodePrivider from '@/resources/promotion-code.provider'
import OrderProvider from '@/resources/order.provider'

const PromotionCodeService = new PromotionCodePrivider()
const OrderService = new OrderProvider()
const state = {
  items: [],
  promotionCode: null,
  discount: 0,
  oldNet: 0,
  payment: {
    id: null
  },
  billResult: null,
  returnItems: [],
  cashAmount: 0,
  bill: {
    orderId: '',
    id: '',
    member: { tel: '' }
  }
}

const actions = {
  async validateCode (store: ActionContext<typeof state, any>, code: string): Promise<any> {
    try {
      const items = calculateStepPricing(store.state.items)
      const { bill } : any = store.state
      const { data } = await PromotionCodeService.superValidatePromotionCode({
        code,
        member: { tel: store.state.bill.member.tel },
        channel: bill.channel,
        orderId: bill.orderId,
        items: items.map((item: any) => ({ ...item, skuId: item.id, brand: { id: 1, name: 'gentlewoman' } }))
      })
      if (data.success) {
        store.commit('SET_DISCOUNT', data.discount)
        store.commit('SET_PROMOTIONCODE', data.promotionCode)
      }
      if (data.success) {
        return { success: true, message: 'This code can be use.' }
      }
      return { success: data.success, message: data.error.message }
    } catch (err: any) {
      return { message: err.message, success: false }
    }
  },
  addToCart (store: ActionContext<typeof state, any>, item: any): void {
    store.commit('ADD_ITEMS', item)
    store.dispatch('clearDiscount')
  },
  async createOrder (store: ActionContext<typeof state, any>): Promise<any> {
    try {
      const skus = calculateStepPricing(store.state.items)
      const { discount, promotionCode, bill, payment, returnItems } = store.state
      const { warehouse } = store.rootState.Store.selectBranch
      const expire = new Date()
      const paymentChannel = store.rootState.Store.paymentChannels.find((pm: any) => pm.id === payment.id)
      const payload = {
        ...bill,
        skus: [...skus, ...returnItems],
        discount,
        promotionCode,
        warehouse,
        payments: [{
          debtAmount: store.getters.net,
          payOrder: 1,
          sumDebtAmount: store.getters.net,
          payAmount: store.getters.net,
          channel: paymentChannel.name,
          channelId: paymentChannel.id,
          channelType: paymentChannel.type,
          deadlineDate: expire,
          note: '',
          paidDate: expire
        }],
        regenFrom: {
          orderId: bill.orderId,
          id: bill.id
        }
      }
      const { data } = await OrderService.completeExchangeOrder(payload)
      store.commit('SET_BILL_RESULT', data)
      return {
        success: true,
        message: 'success'
      }
    } catch (err: any) {
      return {
        success: false,
        message: err?.message || ''
      }
    }
  },
  setItems (store: ActionContext<typeof state, any>, payload: any[]): void {
    store.commit('SET_ITEMS', payload)
  },
  clearDiscount (store: ActionContext<typeof state, any>) {
    store.commit('SET_DISCOUNT', 0)
    store.commit('SET_PROMOTIONCODE', null)
  },
  removeItems (store: ActionContext<typeof state, any>, { skuId, operation }: any): void {
    store.commit('REMOVE_ITEMS', { skuId, operation })
    store.dispatch('clearDiscount')
  },
  setReturnItems (store: ActionContext<typeof state, any>, items: any[]): void {
    store.commit('SET_RETURN_ITEMS', items)
  },
  setPromotionCode (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_PROMOTIONCODE', payload)
  },
  setDiscount (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_DISCOUNT', payload)
  },
  setPayment (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_PAYMENT', payload)
  },
  setCashAmount (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_CASH_AMOUNT', payload)
  },
  setBill (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_BILL', payload)
  },
  setOldNet (store: ActionContext<typeof state, any>, payload: number): void {
    store.commit('SET_OLD_NET', payload)
  }
}

const mutations = {
  ADD_ITEMS (state: any, data: any): void {
    state.items.push(data)
  },
  SET_OLD_NET (state: any, data: number): void {
    state.oldNet = data
  },
  SET_ITEMS (state: any, payload: any): void {
    state.items = payload
  },
  SET_BILL_RESULT (state: any, payload: any): void {
    state.billResult = payload
  },
  SET_PROMOTIONCODE (state: any, payload: any): void {
    state.promotionCode = payload
  },
  SET_DISCOUNT (state: any, payload: any): void {
    state.discount = payload
  },
  SET_PAYMENT (state: any, payload: any): void {
    state.payment = payload
  },
  REMOVE_ITEMS (state: any, { skuId, operation }: any): void {
    const index = state.items.findIndex((item: any) => item.id === skuId)
    const item = state.items.splice(index, 1)
    if (operation === 'old_item') {
      state.returnItems.push({ ...item[0], operation: 'return' })
    }
  },
  SET_CASH_AMOUNT (state: any, payload: any): void {
    state.cashAmount = payload
  },
  SET_BILL (state: any, payload: any): void {
    state.bill = payload
  },
  SET_RETURN_ITEMS (state: any, payload: any) {
    state.returnItems = payload
  }
}

const getters = {
  payment: (state: any) => state.payment,
  calculatedItems: (state: any): any[] => calculateStepPricing(state.items),
  tel: (state: any): any => state.bill.member.tel,
  discount: (state: any) => state.discount,
  fullPrice: (
    state: any
    ) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.fullPrice, 0),
  totalPrice: (
    state: any
  ) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.price, 0),
  net: (
    state: any
  ) => {
    const total = getters.totalPrice(state) - state.discount
    return total < 0 ? 0 : total
  },
  payMore: (
    state: any
  ) => {
    const total = getters.net(state) - state.oldNet
    return total < 0 ? 0 : total
  },
  promotionCode: (state: any) => state.promotionCode,
  bill: (state: any) => state.billResult
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
