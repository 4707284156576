







































































































import { Component, Vue } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductList from './components/product-list.vue'

@Component({
  components: {
    ProductList,
    VueTelInput
  },
  computed: {
    ...mapState({
      cartTel: (state: any) => state.Cart.tel,
      promotionCode: (state: any) => state.Cart.promotionCode
    }),
    ...mapGetters({
      cartAmount: 'Cart/cartAmount',
      calculatedItems: 'Cart/calculatedItems',
      discount: 'Cart/discount',
      fullPrice: 'Cart/fullPrice',
      totalPrice: 'Cart/totalPrice',
      net: 'Cart/net',
      memberTel: 'Cart/tel'
    })
  },
  methods: {
    ...mapActions({
      setNationality: 'Cart/setNationality',
      setGender: 'Cart/setGender',
      clearCartItems: 'Cart/clearCartItems',
      clearDiscount: 'Cart/clearDiscount',
      setTel: 'Cart/setTel',
      setPromotionCode: 'Cart/setPromotionCode',
      validateCode: 'Cart/validateCode',
      getStepPricing: 'Cart/getStepPricing'
    })
  }
})
export default class Cart extends Vue {
  readonly setNationality!: any

  readonly setGender!: any

  clearCartItems!: any

  getStepPricing!: any

  fullPrice!: any

  totalPrice!: any

  discount!: any

  setTel!: any

  cartTel!: any

  validateCode!: any

  clearDiscount!: any

  promotionCode!: any

  setPromotionCode!: any

  memberTel!: any

  loading = false

  cartAmount!: any

  calculatedItems!: any

  showFullPriceFormat!: any

  net!: any

  tel = ''

  code = ''

  get isDisableApplyBtn (): boolean {
    if (this.cartTel === null) {
      return true
    }
    if (this.promotionCode != null) {
      return true
    }
    return false
  }

  get backTo (): string | (string | null)[] {
    return this.$route?.query?.from || 'product-list'
  }

  async validDateCodeHandle (): Promise<void> {
    this.loading = true
    const result = await this.validateCode(this.code.toUpperCase())
    if (result.success) {
      this.$buefy.dialog.alert('This code can be use.')
    } else {
      this.$buefy.dialog.alert(`<span style="color: red">${result.message}</span>`)
    }
    this.loading = false
  }

  selectNationality (): void {
    if (!this.memberTel) {
      this.$buefy.toast.open({ message: 'ไม่ได้กรอกเบอร์ลูกค้านะ', type: 'is-danger' })
    }
    this.setGender(null)
    this.setNationality('Thailand')
    this.$router.push({ name: 'Nationality' })
    // this.$router.push({ name: 'Payment' })
  }

  async mounted (): Promise<void> {
    await this.getStepPricing()
    if (this.promotionCode != null) {
      this.code = this.promotionCode.code
    }
    this.tel = this.cartTel === null ? '' : this.cartTel
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onInputTel (event: any, option: any): void {
    if (option.valid) {
      this.setTel(option.number)
    } else {
      this.code = ''
      this.setTel(null)
      this.clearDiscount()
    }
  }

  confirmClearCart (): void {
    this.$buefy.dialog.confirm({
      message: 'ล้างรายการ ?',
      onConfirm: () => this.clearCartItems()
    })
  }
}
