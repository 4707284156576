





































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import OrderProvider from '@/resources/order.provider'
import ModalProductAddtoCart from './components/modal-product-list.vue'

const orderService = new OrderProvider()

@Component({
  components: { ModalProductAddtoCart },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    }),
    ...mapGetters({
      calculatedItems: 'ExchangeOrder/calculatedItems',
      tel: 'ExchangeOrder/tel',
      net: 'ExchangeOrder/net',
      payMore: 'ExchangeOrder/payMore',
      discount: 'ExchangeOrder/discount',
      fullPrice: 'ExchangeOrder/fullPrice',
      totalPrice: 'ExchangeOrder/totalPrice',
      promotionCode: 'ExchangeOrder/promotionCode'
    })
  },
  methods: {
    ...mapActions({
      setItems: 'ExchangeOrder/setItems',
      setBill: 'ExchangeOrder/setBill',
      setDiscount: 'ExchangeOrder/setDiscount',
      setPromotionCode: 'ExchangeOrder/setPromotionCode',
      validateCode: 'ExchangeOrder/validateCode',
      clearDiscount: 'ExchangeOrder/clearDiscount',
      removeItems: 'ExchangeOrder/removeItems',
      setReturnItems: 'ExchangeOrder/setReturnItems',
      setOldNet: 'ExchangeOrder/setOldNet'
    })
  }
})
export default class Home extends Vue {
  setBill!: any

  showFullPriceFormat!: any

  totalPrice!: any

  setReturnItems!: any

  setItems!: any

  validateCode!: any

  setOldNet!: any

  loading = false

  clearDiscount!: any

  discount!: any

  payMore!: any

  code = ''

  selectBranch!: any

  setDiscount!: any

  setPromotionCode!: any

  promotionCode!: any

  selected: any[] = []

  order: any = {}

  removeItems!: any

  oldTotalPrice = 0

  calculatedItems!: any

  modalAddToCart = false

  get isDisableApplyBtn (): boolean {
    if (this.order?.member?.tel && this.order.member.tel === null) {
      return true
    }
    if (this.promotionCode != null) {
      return true
    }
    return false
  }

  get cartAmount (): number {
    return this.selected.length
  }

  removeSku (id: string, operation: string): void {
    this.removeItems({ skuId: id, operation })
  }

  async validDateCodeHandle (): Promise<void> {
    this.loading = true
    const result = await this.validateCode(this.code.toUpperCase())
    if (result.success) {
      this.$buefy.dialog.alert('This code can be use.')
    } else {
      this.$buefy.dialog.alert(`<span style="color: red">${result.message}</span>`)
    }
    this.loading = false
  }

  checkOut (): void {
    this.setItems(this.selected)
    this.setBill(this.order)
    if (this.selected.length === this.order.skus.length) {
      const promo = this.order.promotionCode.code ? this.order.promotionCode : null
      this.setPromotionCode(promo)
      this.setDiscount(promo !== null ? this.order.discount : 0)
    }
    this.$router.push({ name: 'ClickCollectCart' })
  }

  headers = [
    { label: 'Product List', field: 'productInfo' },
    { label: 'Qty', field: 'amount' },
    { label: 'Price', field: 'price' }
  ]

  private spiltOrderItem (): any[] {
    const result: any[] = []
    const allItems = []
    for (const order of this.order.subOrders) {
      const items = order.skus.map((sk: any) => ({
        ...sk,
        warehouse: order.warehouse
      }))
      allItems.push(...items)
    }
    allItems.forEach((element: any) => {
      for (let i = 0; i < element.amount; i++) {
        result.push({
          ...element,
          operation: 'old_item',
          price: element.price / element.amount,
          fullPrice: element.fullPrice / element.amount,
          amount: 1
        })
      }
    })
    return result
  }

  async mounted (): Promise<void> {
    const { data } = await orderService.getOrderById(this.orderId)
    this.order = data
    this.oldTotalPrice = +data.net
    this.setOldNet(this.oldTotalPrice)
    data.skus = this.spiltOrderItem()

    this.setItems(data.skus)
    this.setBill(this.order)
    this.setReturnItems([])
    const promo = this.order?.promotionCode?.code ? this.order.promotionCode : null
    this.code = promo ? promo.code : ''
    this.setPromotionCode(promo)
    this.setDiscount(promo !== null ? this.order.discount : 0)
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get orderId (): string {
    return this.$route.params.orderId ? this.$route.params.orderId : ''
  }
}
