/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class MemberProvider extends HttpRequest {
  getMemberByTel (tel: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`members/${tel}/tel`)
  }

  getMemberOrderlist (options: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.getByPagination('/members/order-history/paginate', options)
  }

  getMemberById (id: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`members/${id}`)
  }

  updateMember (memberId: string, memberData: any) {
    this.setHeader(getAuthToken())
    return this.update(`/members/${memberId}`, memberData)
  }
}

export default MemberProvider
